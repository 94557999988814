<template>
    <div class="bottom-bar">      
      <footer>
        <div class="contactArea">
          <div class="contactItem">
            <a href="tel:0903745077" target="_blank" class="call">
              <img  src="../../public/imgs/phoneIcon.svg" alt="전화문의">
            </a>
            : 
            <a class="link-text" href="tel:0903745077">0903745077</a>
          </div>
          <div class="contactItem">
            <a href="http://qr.kakao.com/talk/IPaaewyCbKbsb8gtzCR_KPP_nyQ-" target="_blank" class="kakao">
              <img  src="../../public/imgs/kakaoicon.svg" alt="카카오톡">
            </a>
            : 
            <a class="link-text" href="http://qr.kakao.com/talk/IPaaewyCbKbsb8gtzCR_KPP_nyQ-"> nkricecake </a>
          </div>
          <div class="contactItem">
            <a href="https://www.instagram.com/rice.cake.hcm/" target="_blank" class="instargram">
              <img  src="../../public/imgs/instaicon.svg" alt="인스타그램">
            </a>
            : 
            <a class="link-text" href="https://www.instagram.com/rice.cake.hcm/">@rice.cake.hcm</a>
          </div>
        </div>
        <div>
          <address>49 Hậu Giang, Phường 4, Tân Bình, HCMC</address>
        </div>
      </footer>      
    </div>
</template>
  
<script>
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .bottom-bar{
    width: 100%;
    max-width:1360px;
    margin-inline: auto;
    padding: 0.5rem 0;

    position: sticky;
    background: #3c3c3bcc;

    border-radius: 1.5rem 1.5rem 0 0;
  }

  .contactArea{
    display: flex;
    margin-inline: auto;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    justify-content: center;
  }
  .contactItem{
    display: flex;
    align-items: center;
    padding-inline: 1rem;
  }

  .contactItem a{
   text-decoration: underline;
   color: aquamarine;
   font-size: 2rem;
  }

  a img{
   width: auto;
   height: 3rem;
  }

  address{
    color: white;
    font-size: 1.5rem;
    padding-bottom: 0.5rem;
  }
  .link-text {
    display: none; /* 기본적으로 링크를 숨김 */
  }

  @media screen and (max-width:480px) {
    .bottom-bar{
      bottom: -2rem;
      margin-inline: 0.3rem;
    }
    a img{
      height: 2.5rem;
    }
    
    .contactItem {
      margin-top: 0.1rem;
      margin-inline: 0.5rem;
    }
    a img{
      height: 2rem;
    }
    address{
      font-size: 1.1rem;
    }
  }

  @media screen and (min-width:481px) and (max-width:768px) {
    .bottom-bar{
      position: sticky;
    }
    .contactArea{
      margin-top: 0.3rem;
    }
    .contactItem {
      margin-top: 0.1rem;
      margin-inline: 0.1rem;
    }
    .link-text {
      display: inline; /* 481px 이상일 때 링크를 다시 표시 */
    }
    a img{
      height: 1.7rem;
    }
    .contactItem a{
      font-size: 1.2rem;
    }
    address{
     font-size: 1.2rem;
     padding-bottom: 0.2rem;
    }
  }

  @media screen and (min-width:768px) and (max-width:1359px){
    /* .bottom-bar{
      bottom: 0px;
    } */
    a img{
      height: 2.5rem;
    }
    .contactItem a {
      font-size: 1.5rem;
    }
    address{
      font-size: 1.5rem;
    }
  }

</style>
  