<template>
  <div class="itemBox" v-if="menuObj.title">

    <!-- v-if="menuObj.imgname" 이미지가 없을 경우 박스를 안보이게 할 수 있다. -->
    <div class="imgBox" ref="imgBox" @click="fn_showPopup">
        <img :src="state.smallImgPath" :alt="menuObj.title + ' - ' + menuObj.vtn"
             @mouseover="fn_addBorder" @mouseleave="fn_removeBorder">
    </div>
    <h1>{{ userLang === 'ko' ? menuObj.title : menuObj.entitle }}</h1>
    <h2 v-if="menuObj.vtn">{{ menuObj.vtn }}</h2>
    <ImagePop
      ref="imagePop"
      :imageSrc="state.bigImgPath"
      :imgSize="fn_calculateImgSize()" 
      :title="menuObj.title" 
      :entitle="menuObj.entitle"
      :vtn="menuObj.vtn" 
      :userLang="userLang"
      @emitPopClose="fn_closePop"
      v-if="state.isShowImgPop" />
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import ImagePop from './ImagePop.vue';

export default {
  props: {
      menuObj: Object,
      userLang: String
  },
  components: {
    ImagePop
  },
  setup(props) {
    const state = reactive({
      isShowImgPop: false,
      smallImgPath: props.menuObj.imgname =='' ? 'imgs/noImage.svg' : 'imgs/small/' + props.menuObj.imgname + '.webp',
      bigImgPath: props.menuObj.imgname =='' ? 'imgs/noImage.svg' : 'imgs/big/' + props.menuObj.imgname + '.webp'
    });

    const imgBox = ref(null);

    const fn_addBorder = () => {
      if (imgBox.value) {
        fn_changeBorder('#6b6969', '5px');
      }
    };

    const fn_removeBorder = () => {
      if (imgBox.value) {
        fn_changeBorder('#b3a3a3', '5px');
      }
    }

    const fn_changeBorder = (newColor, newWidth) => {
      imgBox.value.style.borderColor = newColor; // Remove border color
      imgBox.value.style.borderWidth = newWidth;
    }

    const fn_showPopup = () => {
      state.isShowImgPop = true;
    }

    const fn_closePop = () => {
      state.isShowImgPop = false;
    }
    // 이미지 크기를 계산하여 반환하는 메서드
    const fn_calculateImgSize = () => {
      // 가로 모드일 때와 세로 모드일 때 각각 다른 값을 반환하도록 구현
      return window.innerWidth <= window.innerHeight ? [window.innerWidth * 0.8 + "px", "auto"] : ["auto", window.innerHeight * 0.8 + "px"];
    }

    return { 
      state,
      imgBox,
      fn_addBorder,
      fn_removeBorder,
      fn_showPopup,
      fn_closePop,
      fn_calculateImgSize,
    }
  }
}
</script>

<style scoped>
  .itemBox {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
  }
  .imgBox {
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
    border: 5px solid #b3a3a3;
    border-radius: 10%;
    background: #fff;
    width: 90%;
    height: 90%;
    max-width: 400px;
    max-height: 400px;
  }
  h1 {
    margin: 0px;
    font-size: 3rem;
  }
  h2 {
    margin: 0px;
    font-size: 2.5rem;
  }
  .itemBox .imgBox img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width:480px) {
    h1 {
      font-size: 1.2rem;
    }
    h2 {
      font-size: 0.9rem;
    }
    .itemBox {
      margin-bottom: 1rem;
    }
  }
  @media screen and (min-width: 481px) and (max-width: 768px){
    h1 {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 1.2rem;
    }
    .itemBox {
      margin-bottom: 1.5rem;
    }
  }
  @media screen and (min-width: 769px) {
    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.5rem;
    }
  }
</style>
