import { defineStore } from 'pinia'
import axios from 'axios';


export const useMenuData = defineStore('menuList', {
  // const state = ({
  state: () => ({
    menuList: null,

    // 한 항목만 수정되었을 경우에 디비에 따로 저장한다.
    selectedMenuItem: null,

    // 나중에 저장을 눌렀을 때 값이 바뀌었을 경우에만 디비에 저장하자.
    // modifyMenuList: false,

    // savedFileName: '',
  }),

  getters: {
    // 자동으로 반환 유형을 배열로 유추함.
    getMenuList(state) {
      return state.menuList;
    },
    getSelectedMenuItem(state) {
      return state.selectedMenuItem;
    },
    // getSavedFileName(state){
    //   return state.savedFileName;
    // },
  },

  actions: {
    // 데이터를 사용하는 곳과 컨트롤 하는 곳을 분리해서 코드를 간결화하자.
    // MVC 모델로 따진다면 controller에 해당
    // 여기 값을 수정해주면 각 View 컴퍼넌트는 알아서 업데이트를 해준다.
    async fn_fetchMenuList() {
      // console.log("Fetching menu list");
      const apiPath = "query/menuList.php";
      await axios.get(apiPath)
        .then(response => {
          this.menuList = response.data;
          console.log(response.data);

          // 다운 받으면 순서를 연결된 숫자로 바꾸어주자.
          this.menuList = this.fn_reOrderUpdateMenuList(this.menuList);
          // 다운 받으면 수정되기 전이므로 false
          // this.modifyMenuList = false;
        })
        .catch(error => {
          console.error('에러 발생:', error);
        }
        );
    },

    fn_setSelectMenuItem(tteok_id) {
      const selectedMenuList = this.menuList.filter(item => item.tteokID == tteok_id);

      this.selectedMenuItem = selectedMenuList[0];
    },
    fn_itemMoveUp(tteok_id) {
      const itemIndex = this.menuList.findIndex(item => item.tteokID === tteok_id);
      if (itemIndex > 0) {
        const temp = this.menuList[itemIndex];
        this.menuList[itemIndex] = this.menuList[itemIndex - 1];
        this.menuList[itemIndex - 1] = temp;

        this.menuList = this.fn_reOrderUpdateMenuList(this.menuList);
      }
    },

    fn_itemMoveDown(tteok_id) {
      const itemIndex = this.menuList.findIndex(item => item.tteokID === tteok_id);
      if (itemIndex < this.menuList.length - 1) {
        const temp = this.menuList[itemIndex];
        this.menuList[itemIndex] = this.menuList[itemIndex + 1];
        this.menuList[itemIndex + 1] = temp;

        this.menuList = this.fn_reOrderUpdateMenuList(this.menuList);
      }
    },

    fn_reOrderUpdateMenuList(sourcelist) {
      // 배열의 순서만 바꾼 후에 그 순서를 바로 tteokID에 넣어준다.
      for (let i = 0; i < sourcelist.length; i++) {
        // console.log(i, state.menuList[i]);
        sourcelist[i].tteokID = (i + 1);
      }

      return sourcelist;
    },

    fn_updateAllMenuList() {
      // if(this.modifyMenuList){
      // productTypeInfo과 storeMenuInfo는 다른 UI로 갔다.
      // productTypeInfo는 간단한 내용으로 테이블에서 바로 추가하고, 수정이 가능하게 만들었다.
      // 그래서 수정된 내용은 update로 추가된 내용은 insert로 구분해서 해줘야 한다.

      // 그러나 storeMenuInfo는 전체 항목의 숫자도 많고, 각 칼럼도 여러개로 구성이 되어 있어.
      // 팝업으로 추가하고 수정하고 바로 저장하도록 만들었다.
      // 전체 저장은 항목이 추가삭제가 없으므로 그냥 업데이트만 하면 된다.

      const apiPath = "query/updateMenuList.php";
      this.fn_sendPostData(apiPath, this.menuList);
      // }
    },

    fn_setEmptySelectMenuItem() {
      // 팝업에 쓸 수 있게 각 항목을 더미로 생성해준다.
      // reduce 함수를 사용하여 배열에서 최대값을 찾기
      const maxIndexNum = this.fn_getMaxImgIndexNum();

      this.selectedMenuItem = {
        "tteokID": maxIndexNum + 1,
        "title": "",
        "entitle": "",
        "vtn": "",
        "imgname": "",
        "productTypeID": 1,
        "productType": "떡",
        "subType": "쌀떡"
      }
    },

    // fn_setImgName(imgName){
    //   this.selectedMenuItem.imgname = imgName;
    // },

    fn_getMaxImgIndexNum() {
      const maxIndexNum = this.menuList.reduce((max, item) => {
        return item.tteokID > max ? item.tteokID : max;
      }, -Infinity);

      return maxIndexNum;
    },

    fn_setProductType(typeObj) {
      this.selectedMenuItem.productTypeID = typeObj.productTypeID;
      this.selectedMenuItem.productType = typeObj.productType;
      this.selectedMenuItem.subType = typeObj.subType;
    },

    fn_updateMenuItem(newIndex) {
      this.menuList = this.fn_reOrderUpdateMenuList(this.menuList);
      for (let i = 0; i < this.menuList.length; i++) {
        if (this.menuList[i].title === this.selectedMenuItem.title && this.menuList[i].imgname === this.selectedMenuItem.imgname) {
          // console.log(this.selectedMenuItem.tteokID, newIndex);

          if (newIndex < 1) {
            newIndex = 1;
          } else if (newIndex > this.menuList.length) {
            newIndex = this.menuList.length;
          }
          
          this.fn_moveArrayElement(this.menuList, this.selectedMenuItem.tteokID - 1, newIndex);
          this.menuList = this.fn_reOrderUpdateMenuList(this.menuList);
          break;
        }
      }

      // 순서가 바뀔 수도 있으므로 전체 리스트를 갱신하자.
      this.fn_updateAllMenuList();
      // for (let i = 0; i < this.menuList.length; i++) {
      //   console.log (this.menuList[i].tteokID , this.menuList[i].title ) 
      // }

      // // DB에 저장할 때 전체 리스트도 함께 고친다.
      // // 그럼 굳이 리턴값 받거나 서버와 통신 한번 더 할 필요가 없다.
      // for(let i=0;i< this.menuList.length; i++){
      //   if(this.menuList[i].tteokID === this.selectedMenuItem.tteokID){
      //     this.menuList[i] = this.selectedMenuItem;
      //     break;
      //   }
      // }

      // // entitle, imgname, productTypeID, title, tteokID, vtn
      // // 서버로 데이터 전송
      // const apiPath = "query/updateMenuItem.php";
      // // console.log(this.selectedMenuItem);
      // this.fn_sendPostData(apiPath, this.selectedMenuItem);
    },

    fn_moveArrayElement(arr, fromIndex, toIndex) {

      const element = arr.splice(fromIndex, 1)[0];
      arr.splice(toIndex, 0, element);

      return arr;
    },

    fn_addMenuItem() {
      // console.log(this.selectedMenuItem.title);
      const isExist = this.menuList.some(item => item.title === this.selectedMenuItem.title);

      if (!isExist) {
        // 동일한 이름이 없을 경우에만 데이터를 추가한다.

        // Proxy 객체 생성
        this.menuList.push(this.selectedMenuItem);

        // 서버로 데이터 전송
        const apiPath = "query/addMenuItem.php";
        this.fn_sendPostData(apiPath, this.selectedMenuItem);
      }
      return isExist;

    },

    fn_deleteMenuItem() {
      // 삭제할 내용을 menuList에서도 제거해줌.
      const tteok_id = this.selectedMenuItem.tteokID;
      
      this.menuList = this.menuList.filter(item => item.tteokID !== tteok_id);
      //console.log(tteok_id, this.selectedMenuItem );
      //console.log(this.menuList );

      const apiPath = "query/deleteMenuItem.php";
      this.fn_sendPostData(apiPath, this.selectedMenuItem);
    },

    fn_updateFileName(newImgName) {
      // 서버에 이미지 올리고, 
      // this.menuList와 this.selectedMenuItem에
      // 해당 인덱스의 변경된 이미지 이름을 적용해준다.
      this.selectedMenuItem.imgname = newImgName;

      const tteok_id = this.selectedMenuItem.tteokID;
      this.menuList = this.menuList.map(item => {
        if (item.tteokID === tteok_id) {
          // update인 경우 해당 아이디만 이름 변경
          // 이렇게 하면 추가일 경우는 menuList에서 바뀔 것이 없음.
          item.imgname = newImgName;
        }
        return item;
      });

      // console.log(newImgName); 
    },
    fn_sendPostData(path, data) {
      axios.post(path, {
        menuData: data
      })
        .then(response => {
          if (response.status === 200) {
            console.log('전송 성공', response.data);
          } else {
            console.error('전송 오류');
          }
        })
        .catch(error => {
          console.error('에러 발생:', error);
        });
    }
  },
})
