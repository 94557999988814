<template>
  <div class="menu-area">
    <div class="info">
        <p>
            ※
        </p>
        <p>
          {{clickBigger }}
            
        </p>
    </div>
    <div class="menuBox">
      <!-- <div class="eachItem" v-for="(item, index) in state.menuDataList" :key="index"> -->
      <div class="eachItem" v-for="(item, index) in storeMenuList.getMenuList" :key="index">        
        <MenuItem :menuObj="item" :userLang="state.userLanguage" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue';
import MenuItem from './menuItem.vue'
import { useMenuData } from '../../stores/storeMenuInfo'

export default {
  name: 'TteokMenu',
  components: {
    MenuItem
  },
  setup() {
    const state = reactive({
      userLanguage: navigator.language.substring(0, 2)// 사용자의 언어 설정 얻기
    })
    // 데이터를 가져오자
    const storeMenuList = useMenuData();
    let clickBigger = 'Click on the image to view it larger.';
    if( state.userLanguage === 'ko'){
      clickBigger = '이미지를 클릭하시면 더 크게 보실 수 있습니다.';
    }else if( state.userLanguage === 'vi'){
      clickBigger = 'Bấm vào hình ảnh để xem nó lớn hơn.';
    }
    
    onMounted(() => {
      storeMenuList.fn_fetchMenuList();
    });

    return {
      state,
      storeMenuList,
      clickBigger
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .menu-area{
    width: 100%;
    max-width:1360px;
    margin-inline: auto;
    margin-top: 1rem;
    /* background: url( "../../public/imgs/bamboo3.svg" );
    background-repeat: repeat; */
  }
  .info {
    position:sticky;
    top:0px;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    padding:15px 0;
    font-weight:700;
    background:#f2ecec;
    color:#666261;
    font-size:1.5rem;
  }
  .eachItem {
    width: 32%;
  }
  .info p {
    margin:0;
  }
  .info p:first-of-type {
    margin-top:-3px
  }
  .menuBox{
    max-width: 90%;
    margin: 28px auto 10px auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  /* @media screen and (min-width:320px) {
    .info {
      font-size:large
    }
  } */

  @media screen and (max-width:480px) {
    .menu-area{
      margin-top: 0.5rem;
    }
    .info {
      /* 이걸루 모바일폰 세로일 때만 로고와 타이틀 영역이 스크롤 안되고 고정 */
      top:8rem;
      padding:10px 0;
      font-size: 1rem;
    }
    .eachItem {
      width: 47%;
    }
  }

  @media screen and (min-width:481px) and (max-width:768px) {
    
  }
</style>
