<template>
  <div class="popup-overlay" @click="fn_closePopup">
    <div class="popup-content">
      <!-- <img :src="imageSrc" :alt="title + ' - ' + vtn"> -->
      <img :src="imageSrc" :alt="title + ' - ' + vtn" :style="{ width: state.imgWidth, height: state.imgHeight }">
      
      <div class="popup-text" :style="{top:state.textTopStyle}">
        <h2>{{ userLang === 'ko' ? title : entitle }}</h2>
        <p>{{ vtn }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import { reactive, computed, onMounted } from 'vue'; 

  export default {
    props: {
      imgSize: Array,
      imageSrc: String,
      title: String,
      entitle:String,
      vtn: String,
      textTopStyle: Object,
      userLang:String
    },


    setup(props, { emit }) {
      const state = reactive({
        imgWidth: computed(() => props.imgSize[0]),
        imgHeight: computed(() => props.imgSize[1]),
        textTopStyle: computed(() => fn_popTextStyle()),
        bigImgPath: ""
      })

      onMounted(() => {
        state.bigImgPath = props.imageSrc
      })

      const fn_popTextStyle = () => {
        let topSize = "";
        if(props.imgSize[0] == 'auto'){
          topSize = `calc(50% + 0.43 * ${props.imgSize[1]})`;
        }else{
          topSize = `calc(50% + 0.35 * ${props.imgSize[0]})`;
        }

        return topSize;
      }

      // Vue3에선 setup에서 {emit}를 인자로 꼭 받아줘야 한다.
      const fn_closePopup = () => {
        // 부모에게 창 닫으라고 날려주자.
        emit('emitPopClose');
      }

      return {
        state,
        fn_popTextStyle,
        fn_closePopup
      }
    }
  }
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
    /* width: auto; */

    /* height: 95%; */
    display: flex;
    justify-content: center;
}

img {
    margin-inline: auto;
    width: auto;
    height: auto;
    border-radius: 5%;
    border: 7px solid dimgray;
}

.popup-text {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 1rem;
  /* bottom:2rem; */
}
h2 {
  margin: 0;
  padding-inline: 0.1rem;
  font-size: 3rem; /* 제목 폰트 크기 설정 */
}
p {
  margin: 0;
  padding-inline: 0.1rem;
  font-size: 2.5rem; /* 부제목 폰트 크기 설정 */
}

@media screen and (max-width: 480px) {
    .popup-content{
        /* width: 95%; */
        height: auto;
    }
    .popup-text h2 {
        font-size: 1.5rem; /* 제목 폰트 크기 설정 */
    }
    .popup-text p {
        font-size: 1.1rem; /* 부제목 폰트 크기 설정 */
    }
}

@media screen and (min-width: 481px)  and (max-width: 768px) {
    .popup-content{
        width: auto;
        /* height: 150vh; */
        display: contents;
    }
    
    .popup-text h2 {
        font-size: 1.5rem; /* 제목 폰트 크기 설정 */
    }

    .popup-text p {
        font-size: 1rem; /* 부제목 폰트 크기 설정 */
    }
}
@media screen and (min-width: 769px)  {
  img{
    margin-top: 2rem;
  }
  .popup-text h2 {
      font-size: 1.5rem; /* 제목 폰트 크기 설정 */
  }

  .popup-text p {
      font-size: 1rem; /* 부제목 폰트 크기 설정 */
  }
}
</style>
  