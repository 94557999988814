<template>
  <TitleBar />
  <TteokMenu/>
  <!-- <TteokMenu msg="Tteok menu area"/> -->
  <BottomBar />

</template>

<script>
import TitleBar from '../components/TitleBar.vue'
import TteokMenu from './root/tteokMenu.vue'
import BottomBar from '../components/BottomBar.vue'


export default {
  name: 'App',
  components: {
    TitleBar,
    TteokMenu,
    BottomBar
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
