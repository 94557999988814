<template>
    <header >
        <div class="shop-title">
            <div class="shop-logo">
                <img src="imgs/nakyungLogo1.svg">
            </div>
            <h2 >{{ titleText }}</h2>
        </div>
    </header>
  </template>
  
  <script>
  export default {
    setup() {
      let titleText = 'The Oldest Traditional Korean Rice Cake Shop in Ho Chi Minh.' ;
      
      const curLang = navigator.language.substring(0, 2)
      if(curLang === 'ko'){
        titleText = '호치민의 최초 떡집 : 맛과 전통의 만남';
      }else if(curLang === 'vi'){
        titleText = 'Quán bánh gạo truyền thống Hàn Quốc lâu đời nhất HCMC.';
      }

      return {
        titleText
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    header{
      padding-top: 1.5rem;
      padding-bottom: 0rem;
      margin: 0px auto;
      min-width: 300px;
      width: 100%;
      max-width:1360px;
      background-image: url("../../public/imgs/title_back.png");
      background-position: center; 
      background-repeat: no-repeat; 
      background-size: cover; 
    }

    .shop-title{
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .shop-logo{
      border-radius: 8px;
      background: #ffffff59;
      /* margin-top: 1.5rem; */
    }

    .shop-logo img{
      height: 10rem;
      /* margin-top:2rem; */
    }

    h2{
      font-size: 2.5rem;
      color: #fff;
      margin: 0.4rem auto;
    }

    @media screen and (max-width:480px) {
      header{
        position:sticky;
        top:0;
        margin-inline: 1px;
      }
      .shop-logo img{
        height: 4rem;
      }
      h2{
        font-size:1rem;
      }
    }
    @media screen and (min-width:481px)and (max-width:768px) {
      
      .shop-logo img{
        height: 5rem;
      }
      h2{
        font-size:1.5rem;
      }
    }
  </style>
  